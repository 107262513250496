import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';
// import { getFullName } from '../../shared/helpers/dataUtils';
const url = '/app-personas/calificacion';

export const emptyGrade = {
  id: null,
  calificacion_numerica: '',
  comentarios: '',
  fecha_evaluacion: '',
  estatus_sistema: true,
  materia_alumno: {
    id: null,
    fecha_asignacion: '',
    estatus_sistema: true,
    alumno: null,
    materia: null,
    institucion_educativa: null,
  },
  periodo: {
    id: null,
    dato: '',
    fecha_inicio: '',
    fecha_fin: '',
    estatus_sistema: true,
    institucion_educativa: 1,
  },
  calificacion_nivel_desempenio: {
    id: null,
    dato: '',
    estatus_sistema: true,
  },
  estatus_calificacion: {
    id: null,
    dato: '',
    estatus_sistema: true,
  },
};

export const emptyMateriaAlumno = {
  id: null,
  fecha_asignacion: '',
  estatus_sistema: true,
  alumno: {
    id: null,
    datos_personales: null,
  },
  materia: {
    id: null,
    nombre: '',
    descripcion: '',
    extracurricular: false,
  },
  grade: { ...emptyGrade },
};
export const emptyCalificacion = {
  id_boleta: 1,
  id_materia_alumno: null,
  id_periodo: null,
  id_estatus_calificacion: null,
  id_institucion_educativa: null,
  fecha_evaluacion: null,
  comentarios: '',
  detalle_calificaciones: [],
};
export const emptySubject = {
  area_propedeutica: null,
  campos_formativos: [],
  criterios_evaluacion: [],
  descripcion: '',
  estatus_sistema: true,
  extracurricular: false,
  grade: { ...emptyGrade },
  id: null,
  nombre: '',
  plan_estudios: null,
  titular_materia: null,
};
export const GradeActions = {
  PAUSE: 1,
  ACCEPT: 2,
  REJECT: 3,
  EDIT: 4,
  DETAIL: 5,
};

export const fetchGrades = async ({
  institutionId = null,
  materiaAlumno = null,
  periodo = null,
  mes = null,
  calificacion = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

    const materiaAlumnoFilter =
      materiaAlumno !== null ? `&materia_alumno=${materiaAlumno}` : '';
    const periodoFilter = periodo !== null ? `&periodo=${periodo}` : '';
    const mesFilter = mes !== null ? `&mes=${mes}` : '';
    const calificacionFilter =
      calificacion !== null ? `&calificacion=${calificacion}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/calificacion?'.concat(
        institutionFilter,
        systemStatusFilter,
        materiaAlumnoFilter,
        periodoFilter,
        mesFilter,
        calificacionFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las calificaciones. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener calificaciones. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener calificaciones. ' + error,
      count: 0,
    };
  }
};

export const postGrade = async (grade) => {
  try {
    const response = await postItem(url, grade);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear la calificacion. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear calificacion. ' + error,
    };
  }
};

export const putGrade = async (grade) => {
  try {
    const { id } = grade;
    const response = await putItem(`${url}/${id}`, grade);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar la calificacion. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar calificaciones. ' + error,
    };
  }
};

export const delGrade = async (id) => {
  try {
    const response = await deleteItem(`${url}`, id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar la calificacion. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar calificacion' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar calificacion. ' + error,
    };
  }
};

export const reactivateGrade = async (gradeId) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/reactivar-calificacion',
      {
        id_calificacion: gradeId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reactivar la calificacion. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar reactivar la calificacion. ' + error,
    };
  }
};

export const updateGradeStatus = async (id_calificacion, id_estatus) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/actualizar-estatus-calificacion',
      {
        id_calificacion: id_calificacion,
        id_estatus: id_estatus,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message:
          'Error al intentar actualizar estatus de calificacion. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar estatus de calificacion. ' + error,
    };
  }
};

export const fetchGradeDetails = async ({
  gradeId = null,
  evaluationCriteriaId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const evaluationCriteriaIdFilter =
      evaluationCriteriaId !== null
        ? `&criterio_evaluacion=${evaluationCriteriaId}`
        : '';
    const gradeIdFilter = gradeId !== null ? `calificacion=${gradeId}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/detalle-calificacion?'.concat(
        gradeIdFilter,
        evaluationCriteriaIdFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los detalles de calificacion. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener detalles de calificacion. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener detalles de calificacion. ' + error,
      count: 0,
    };
  }
};
export const gradesPost = async (gradesData) => {
  try {
    const responseData = await postItem(
      '/app-personas/helpers/crear-calificacion',
      gradesData
    );
    if (!responseData || responseData.error || responseData.e) {
      return { ok: false, message: responseData.error, status: responseData.status };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
