import { fetchGrades } from '../../helpers/gradeOptions';
export default {
  data() {
    return {
      finalGrade: null,
      gradeId: null,
      studentArray: [],
      form: {},
      errors: [],
      headers: [
        { text: 'Alumnos', sortable: false },
        { text: 'Materia', value: 'Materia', sortable: false },
        { text: 'Criterio 1', value: 'criteria1' },
        { text: 'Criterio 2', value: 'criteria2' },
        { text: 'Criterio 3', value: 'criteria3' },
        { text: 'Comentarios', value: 'comments', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  async created() {
    this.loadingPage = true;
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.gradeId = this.validateId(paramId);
        await this.loadDataGrade();
      } else return undefined;

      //await this.fillDataForm();
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
  methods: {
    async loadDataGrade() {
      try {
        const { data } = await fetchGrades(this.gradeId);
        const { alumnos } = data;

        this.form = {
          ...data,
          alumnos: alumnos ? alumnos.map((e) => e.id) : [],
        };
        this.studentArray = JSON.parse(JSON.stringify(alumnos));
        // console.log('Form', this.form);
      } catch (error) {
        console.error('Error loading subject data:', error);
      }
    },
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
  },
};
